import { has, intersection, isArray, isEmpty, isEqual, keys, omit, pick, set, unset, without } from 'lodash-es';
import { DEFAULT_SORT, getFilterById, MIXED_GRID_SORT_OPTIONS } from '@phoenix/helpers/mixed-grid-helper';

export default {
  props: {
    uniqueId: {
      type: String,
      required: true,
    },
    shouldDisplaySellable: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Array,
      required: true,
    },
    defaultFilters: {
      type: Object,
      default: () => ({}),
    },
    emptyFilters: {
      type: Object,
      default: () => ({}),
    },
    defaultSort: {
      type: String,
      required: true,
    },
    productsCount: {
      type: Number,
      default: 0,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    hasFiltersInUrl: {
      type: Boolean,
      default: false,
    },
    unclearableFilters: {
      type: Array,
      default: () => [],
    },
    shouldDisplayUnclearableFilters: {
      type: Boolean,
      default: true,
    },
    shouldDisplayProductsCount: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      activeFilters: {},
      currentSort: DEFAULT_SORT,
      sellableFilterId: 'sellable',
    };
  },

  computed: {
    hasFilters() {
      return keys(this.activeFilters).length > 0;
    },

    productCountLabel() {
      let translationKey = 'Search.results';
      if (this.productsCount === 0) {
        translationKey = 'Search.no results';
      } else if (this.productsCount === 1) {
        translationKey = 'Search.result';
      }

      return `${this.productsCount} ${this.$t(translationKey)}`;
    },

    datalayerTrackingViewResults() {
      return JSON.stringify(window.tracking.getProductFinderViewResultsTracking());
    },

    datalayerTrackingClose() {
      return JSON.stringify(window.tracking.getProductFinderCloseTracking());
    },

    availableOnline: {
      get() {
        return has(this.activeFilters, this.sellableFilterId);
      },
      set(value) {
        this.setCheckedFilter(value, this.sellableFilterId, this.sellableFilterId);
      },
    },

    availableFilterIds() {
      const ids = this.filters.map((filter) => filter.id);

      if (this.shouldDisplaySellable) {
        ids.push(this.sellableFilterId);
      }

      return ids;
    },

    filterValuesById() {
      return {
        ...Object.fromEntries(this.filters.map((filter) => [filter.id, filter.items.map((item) => item.value)])),
        [this.sellableFilterId]: [this.sellableFilterId],
      };
    },

    shouldDisplayRemoveAllFilters() {
      return !isEmpty(omit(this.activeFilters, this.unclearableFilters));
    },
  },

  mounted() {
    this.updateFiltersFromUrlParams();
    this.initFilters();
  },

  watch: {
    activeFilters: {
      handler() {
        this.updateUrlParamsFromFilters();
        this.$emit('onFiltersChanged', this.activeFilters);
      },
      deep: true,
    },

    currentSort(value) {
      this.updateUrlParamsFromFilters();
      this.$emit('onSortChange', value);
    },
  },

  methods: {
    initFilters() {},

    getFilterById(filterId) {
      return getFilterById(this.filters, filterId);
    },

    checkedFilterIsNotActive(value, filterId) {
      return value === true && !has(this.activeFilters, filterId);
    },

    uncheckedFilterIsActive(value, filterId) {
      return value === false && has(this.activeFilters, filterId);
    },

    toggleFilter(filterId, value) {
      const component = this.getFilterById(filterId).component;

      if (component === 'select' || component === 'radio') {
        this.addFilter(filterId, [value]);
        return;
      }

      if (this.activeFilters[filterId]?.includes(value)) {
        this.removeFilter(filterId, value);
      } else {
        this.addFilter(filterId, value);
      }
    },

    switchFilter(filterId, value) {
      this.toggleFilter(filterId, [value]);
    },

    onRangeChange(filter, range) {
      if (isEmpty(range)) {
        this.removeFilter(filter.id);
      } else {
        this.addFilter(filter.id, range);
      }
    },

    addFilter(filterId, value) {
      if (!has(this.activeFilters, filterId) && !isArray(value)) {
        value = [value];
      }

      if (isArray(value)) {
        set(this.activeFilters, filterId, value);
      } else {
        this.activeFilters[filterId].push(value);
      }

      try {
        this.addTrackingOnFilter(filterId, value);
      } catch (e) {} // eslint-disable-line
    },

    removeFilter(filterId, value = null) {
      if (value === null || this.activeFilters[filterId].length === 1) {
        unset(this.activeFilters, filterId);
      } else {
        set(this.activeFilters, filterId, without(this.activeFilters[filterId], value));
      }
    },

    removeAllFilters() {
      this.activeFilters = pick(this.activeFilters, this.unclearableFilters);
    },

    getSymbolFromFilter(filter) {
      return filter.id === 'hasprice' ? filter.options.format.symbol : '';
    },

    addTrackingOnFilter(filterId, value) {
      const filter = this.getFilterById(filterId);
      const subFilter = filter?.items.find((filter) => filter.value === value);
      let subFilterLabel = subFilter ? subFilter.trackingLabel : value;
      if (isArray(subFilterLabel)) {
        subFilterLabel = subFilterLabel.join(',');
      }
      window.tracking.pushEvent(
        window.tracking.getFilterTracking(filter ? filter.trackingLabel : filterId, subFilterLabel)
      );
    },

    setCheckedFilter(isChecked, filterId, filterValue) {
      if (this.checkedFilterIsNotActive(isChecked, filterId)) {
        this.addFilter(filterId, filterValue);
      } else if (this.uncheckedFilterIsActive(isChecked, filterId)) {
        this.removeFilter(filterId);
      }
    },

    getValidFilterValues(filterId, values) {
      const filter = this.getFilterById(filterId);

      if (filter && has(filter, ['options', 'noUiSliderOptions', 'range'])) {
        const range = filter.options.noUiSliderOptions.range;
        if (Object.keys(range).length === 2) {
          values = values.slice(0, 2).map((value) => {
            if (value < range.min) {
              return range.min;
            } else if (value > range.max) {
              return range.max;
            }
            return value;
          });
          return [values[0] !== undefined ? values[0] : range.min, values[1] !== undefined ? values[1] : range.max];
        }
      }

      return intersection(values, this.filterValuesById[filterId]);
    },

    updateUrlParamsFromFilters() {
      if (!this.hasFiltersInUrl) {
        return;
      }

      const params = {
        filters: null,
        sort: null,
      };

      if (this.hasFilters) {
        const filters = [];
        for (const [key, value] of Object.entries(this.activeFilters)) {
          filters.push(`${key}=${value.join('|')}`);
        }
        params.filters = filters.join(',');
      }

      if (this.currentSort !== this.defaultSort) {
        params.sort = this.currentSort;
      }

      const url = new URL(window.location.href);
      for (const [key, value] of Object.entries(params)) {
        value ? url.searchParams.set(key, value) : url.searchParams.delete(key);
      }

      const newUrl = url.toString();
      if (window.location.href !== newUrl) {
        window.history.replaceState({ path: newUrl }, '', newUrl);
      }
    },

    updateFiltersFromUrlParams() {
      if (!this.hasFiltersInUrl) {
        return;
      }

      const url = new URL(window.location.href);

      if (url.searchParams.has('filters')) {
        const filters = {};
        url.searchParams
          .get('filters')
          .split(',')
          .forEach((filter) => {
            const [key, value] = filter.split('=');

            if (this.availableFilterIds.includes(key)) {
              filters[key] = this.getValidFilterValues(
                key,
                value.split('|').map((value) => (!isNaN(value) ? parseFloat(value) : value))
              );
            }
          });
        this.activeFilters = filters;
      } else if (!isEqual(this.activeFilters, this.defaultFilters)) {
        this.activeFilters = JSON.parse(JSON.stringify(this.defaultFilters));
      }

      if (url.searchParams.has('sort')) {
        const newSort = url.searchParams.get('sort');
        this.currentSort = Object.keys(MIXED_GRID_SORT_OPTIONS).includes(newSort) ? newSort : this.defaultSort;
      } else if (this.currentSort !== this.defaultSort) {
        this.currentSort = this.defaultSort;
      }
    },
  },
};
