import { map, keys } from 'lodash-es';
import { getFilterById, getFilterItemFromIdAndValue } from '@phoenix/helpers/mixed-grid-helper';

export default {
  props: {
    filters: {
      type: Object,
      required: true,
    },
    activeFilters: {
      type: Object,
      required: true,
    },
    unclearableFilters: {
      type: Array,
      default: () => [],
    },
  },

  emits: ['removeFilter', 'removeAllFilters'],

  computed: {
    activeFiltersCount() {
      let filtersCount = 0;
      map(this.activeFilters, (values) => (filtersCount += values.length));
      return filtersCount;
    },
    hasFilters() {
      return keys(this.activeFilters).length > 0;
    },
  },

  methods: {
    getFilterItemFromIdAndValue,

    getFilterById(filterId) {
      return getFilterById(this.filters, filterId);
    },

    isRange(filterId) {
      return this.getFilterById(filterId).component === 'range';
    },

    isColor(filterId) {
      return this.getFilterById(filterId).component === 'color';
    },

    isSellable(filterId) {
      return filterId === 'sellable';
    },

    isUnclearable(filterId) {
      return this.unclearableFilters.includes(filterId);
    },
  },
};
