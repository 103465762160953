<script>
import MixedGridFiltersItemRange from '@phoenix/mixins/components/mixedGrid/MixedGridFiltersItemRange';

export default {
  mixins: [MixedGridFiltersItemRange],

  emits: ['onRangeChange'],

  computed: {
    rangeClasses() {
      return ['range', { 'btn--disabled': this.filter.disabled }];
    },
  },
};
</script>

<template>
  <h6 class="products-filters-panel__title">{{ filter.label }}</h6>
  <div class="range-slider range-slider--centered" data-cy="mixed-grid-filter-range">
    <div
      :id="uniqueId"
      ref="range"
      :class="rangeClasses"
      :data-property="filter.id"
      :data-label="filter.label"
      :data-options="filter.options"
      :data-items="filter.items"
    ></div>
  </div>
</template>
