import { MIXED_GRID_SORT_OPTIONS } from '@phoenix/helpers/mixed-grid-helper';

export default {
  data() {
    return {
      sortOptions: MIXED_GRID_SORT_OPTIONS,
    };
  },

  props: {
    modelValue: {
      type: String,
      required: true,
    },
  },

  computed: {
    sortLabel() {
      return this.$t('Search.Sort.Label');
    },

    localValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
