import { FEATURE_FLAG, getFeatureFlag } from '@phoenix/helpers/feature-flag-helper';
import { CURRENT_COUNTRY_CHANGED, emitter } from '@phoenix/event-bus';
import { DEFAULT_SORT, getFilterById, MIXED_GRID_SORT_OPTIONS } from '@phoenix/helpers/mixed-grid-helper';
import { parse as queryStringParse } from 'query-string';
import { difference, filter, findIndex, get, has, intersection, isEmpty, max, maxBy, orderBy, set } from 'lodash-es';

export default {
  data() {
    return {
      activeFilters: {},
      currentSort: DEFAULT_SORT,
      columns: [],
      Phoenix: window.Phoenix, // Proxy to be observable by the computed props below
      page: null,
      maxPage: {},
      areSortOrFiltersTouched: false,
      trackedPages: [],
    };
  },

  props: {
    uniqueId: {
      type: String,
      required: true,
    },
    itemsPerPage: {
      default: 12,
      type: Number,
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    products: {
      type: Array,
      default: () => [],
    },
    filters: {
      type: Array,
      default: () => [],
    },
    hasLoadMore: {
      type: Boolean,
      default: true,
    },
    /**
     * Any other components/media that'll take place in the mixed grid.
     */
    media: {
      type: Array,
      default: () => [],
    },
    hasFiltersInUrl: {
      type: Boolean,
      default: false,
    },
    defaultSort: {
      type: String,
      default: DEFAULT_SORT,
    },
    trackingData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    unclearableFilters: {
      type: Array,
      default: () => [],
    },
    shouldDisplayUnclearableFilters: {
      type: Boolean,
      default: true,
    },
    shouldHideSellable: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    /**
     * Available widths for each breakpoints
     */
    widths() {
      return {
        lg: {
          1: '1/4',
          2: '1/2',
          3: '3/4',
          4: '1/1',
        },
        md: {
          1: '1/3',
          2: '2/3',
          3: '1/1',
        },
        sm: {
          1: '1/2',
          2: '1/1',
        },
        xs: {
          1: '1/2',
          2: '1/1',
        },
      };
    },

    /**
     * Update has price filter to set the correct currency and price range
     */
    filtersWithLocalizedPrices() {
      const filters = this.filters;

      if (filters.map((filter) => filter.id).includes('hasprice')) {
        const country = this.Phoenix.currentCountry;
        if (country) {
          let currency = undefined;
          const options = this.getFilterById('hasprice').options;

          const prices = this.products
            .map(({ price }) => {
              if (!(price && price[country])) {
                return null;
              }

              // Set the currency once
              if (!currency) {
                currency = price[country].currency;
              }

              // Return the localized price for the product
              return price[country].value;
            })
            .filter((price) => price !== null && price);

          if (currency) {
            options.format.symbol = `${currency} `;
          }

          // Sort prices
          prices.sort((a, b) => a - b);
          const min = prices[0];
          const max = prices.slice(-1)[0];

          // Set the range options
          if (min && max) {
            options.noUiSliderOptions.range.min = min;
            options.noUiSliderOptions.range.max = max;
            options.noUiSliderOptions.start[0] = min;
            options.noUiSliderOptions.start[1] = max;
          }

          set(filters, [filters.findIndex((filter) => filter.id === 'hasprice'), 'options'], options);
        }
      }

      return filters;
    },

    numberProductsByFilters() {
      const filters = {};

      this.filters.forEach((filter) => {
        const disabledItems = filter.items.map((item) => {
          const activeFilters = { ...this.activeFilters, [filter.id]: [item.value] };
          const filteredColumns = this.filterProducts(this.columns, activeFilters).filter(
            ({ template }) => template === 'product'
          );

          return { nbrProducts: filteredColumns.length, ...item };
        });

        filters[filter.id] = disabledItems.map((item) => item);
      });
      return filters;
    },

    /**
     * Object containing the filters items that should be greyed-out
     * because they match no products of the current listing (i.e. `this.filteredColumns`).
     * Follow the same format as `this.activeFilters`.
     */
    emptyFilters() {
      if (!this.shouldDisableFiltersWithNoResults) {
        return {};
      }

      const filters = {};
      Object.entries(this.numberProductsByFilters).forEach(([filterId, filterItems]) => {
        const disabledItems = filterItems.filter((item) => item?.nbrProducts === 0);
        if (disabledItems.length > 0) {
          filters[filterId] = disabledItems.map((item) => item.value);
        }
      });

      return filters;
    },

    filteredColumns() {
      // Return the default columns if this is the default sort and there is no filter activated
      if (this.hasDefaultSortAndFilters()) {
        return this.columns;
      }
      return this.sortProducts(this.filterProducts(this.columns, this.activeFilters));
    },

    filteredColumnsCount() {
      return this.filteredColumns.filter(({ template }) => template === 'product').length;
    },

    displayFilter() {
      return this.filters && this.filters.length;
    },

    shouldDisplaySellable() {
      return !this.shouldHideSellable && getFeatureFlag(FEATURE_FLAG.ECOM);
    },

    shouldDisplayLoadMore() {
      return this.hasLoadMore && !this.areSortOrFiltersTouched;
    },

    shouldDisableFiltersWithNoResults() {
      return getFeatureFlag(FEATURE_FLAG.DISABLE_FILTERS_NO_RESULTS);
    },

    biggestMaxPage() {
      return max(Object.values(this.maxPage));
    },

    nbOfMaxItems() {
      return this.columns.filter(({ template }) => template === 'product').length;
    },

    trackingListId() {
      return this.trackingData?.listId ?? '';
    },

    trackingListName() {
      const isDynamic = this.trackingData?.isDynamic ?? false;
      const listName = this.trackingData?.listName ?? '';
      return `${listName} - ${isDynamic ? 'dynamic' : 'predefined'}`;
    },
  },

  watch: {
    page() {
      this.updateUrl();
    },

    activeFilters() {
      this.areSortOrFiltersTouched = true;
    },

    currentSort() {
      this.areSortOrFiltersTouched = true;
    },

    filteredColumns() {
      this.trackedPages = [];
      this.trackPages(1, this.page);
      if (window.tracking.getProductListTracking) {
        window.tracking.pushEvent(window.tracking.getProductListTracking(this.trackingListName, this.trackingListName));
      }
    },
  },

  created() {
    this.initColumns();
    this.parseUrl();
    emitter.on(CURRENT_COUNTRY_CHANGED, this.onCountryChanged);
  },

  unmounted() {
    emitter.off(CURRENT_COUNTRY_CHANGED, this.onCountryChanged);
  },

  methods: {
    onCountryChanged(country) {
      this.currentCountry = country;
    },

    trackItemsForPage(page) {
      if (this.trackedPages.includes(page)) {
        return;
      }

      this.trackedPages.push(page);

      const items = this.filteredColumns
        .map((item, index) => ({
          ...item,
          index,
        }))
        .filter((item) => item.page.sm === page && item.template === 'product')
        .map((item) => ({
          ...item.data.tracking,
          index: item.index,
        }));

      let index = 0;
      const chunkSize = 3;

      while (index < items.length) {
        const chunkItems = window.tracking.getProductListDetailsTrackingEvents(
          items.slice(index, index + chunkSize),
          this.trackingListName,
          this.trackingListId
        );
        window.tracking.pushEvents(chunkItems);
        index += chunkSize;
      }
    },

    onSortChange(sort) {
      this.currentSort = sort;
    },

    onFiltersChanged(filters) {
      this.activeFilters = filters;

      this.columns = this.columns.filter(({ template }) => template === 'product');
    },

    trackPages(startPage, endPage) {
      for (let i = startPage; i <= endPage; i++) {
        this.trackItemsForPage(i);
      }
    },

    /**
     * Organizing product and media items as columns
     */
    initColumns() {
      let currentColumnPosition = 1;
      let currentRowIndex = 1;
      const columns = [];

      const resetCurrentColumnPosition = (position, rowIndex) => {
        if (position > 4) {
          rowIndex = rowIndex + 1;
          position = 1;
        }
        return [position, rowIndex];
      };

      for (let i = 0; i < this.products.length; i++) {
        let mediaAdded = true;
        // Try to add media first
        while (mediaAdded) {
          // Try to add a media on the current row / row position
          [currentColumnPosition, mediaAdded] = this.addMediaForRowIndex(
            columns,
            currentRowIndex,
            currentColumnPosition,
            null
          );

          [currentColumnPosition, currentRowIndex] = resetCurrentColumnPosition(currentColumnPosition, currentRowIndex);
        }

        // Add the product at the current row position
        const data = this.products[i];
        const sizes = data.wide
          ? {
              xs: 2,
              sm: 2,
              md: 1,
              lg: 2,
            }
          : {};
        const column = {
          data,
          template: 'product',
          row: currentRowIndex,
          size: 1,
          sizes,
          wide: data.wide || false,
        };
        columns.push(column);
        currentColumnPosition += data.wide ? 2 : 1;

        // Check if the row size is out of bounds (max. 4 columns)
        [currentColumnPosition, currentRowIndex] = resetCurrentColumnPosition(currentColumnPosition, currentRowIndex);
      }

      // Add remaining media items, if any
      for (let mediaItemIndex = 0; mediaItemIndex < this.media.length; mediaItemIndex++) {
        [currentColumnPosition] = this.addMediaForRowIndex(
          columns,
          currentRowIndex,
          currentColumnPosition,
          mediaItemIndex
        );
        [currentColumnPosition, currentRowIndex] = resetCurrentColumnPosition(currentColumnPosition, currentRowIndex);
      }

      // Once rows are set, calculate the columns sizes for each breakpoints
      this.columns = this.initMediaSizes(columns);

      const currentSize = {};
      for (const size of Object.keys(this.widths)) {
        currentSize[size] = 0;
      }

      for (const column of this.columns) {
        column.page = {};

        Object.keys(currentSize).forEach((size) => {
          let columnSize;
          if (['sm', 'xs'].includes(size) && column.template !== 'product' && this.shouldHideMediaItemsOnMobile) {
            columnSize = 0;
          } else {
            columnSize = column.sizes && column.sizes[size] ? column.sizes[size] : column.size;
          }

          currentSize[size] += columnSize;
          column.page[size] = Math.ceil(currentSize[size] / this.itemsPerPage);
        });

        this.maxPage = { ...column.page };
      }
    },

    /**
     * Check if a media can be added at the current row index and row position
     */
    addMediaForRowIndex(columns, currentRowIndex, currentColumnPosition, mediaItemIndex) {
      const isLeftOver = mediaItemIndex !== null;

      if (!isLeftOver) {
        // Check if there's an item for this row
        mediaItemIndex = findIndex(this.media, {
          row: currentRowIndex,
          position: currentColumnPosition,
        });

        if (mediaItemIndex < 0) {
          return [currentColumnPosition, false];
        }
      }

      const column = this.media.splice(mediaItemIndex, 1)[0];
      column.position = isLeftOver ? currentColumnPosition : column.position;
      column.row = currentRowIndex;
      column.sizes = {};

      currentColumnPosition = currentColumnPosition + (column.size ? column.size : 1);
      columns.push(column);
      return [currentColumnPosition, true];
    },

    /**
     *
     */
    initMediaSizes(columns) {
      columns.forEach((column, index) => {
        if (column.template === 'product') {
          if (!column.wide) {
            return;
          }
          column.sizes.lg = 2;
          column.sizes.md = 1;
          column.sizes.sm = 2;
          column.sizes.xs = 2;
          return;
        }

        // Scope `lg` with four available
        column.sizes.lg = column.size;

        // Scope `md` with three columns available
        column.sizes.md = 1;

        // Scope `sm` with two columns available
        const predecessorsWeigthSm = this.getPredecessorsWeight(columns, index, 'sm');
        column.sizes.sm = (predecessorsWeigthSm % 2 === 0 || predecessorsWeigthSm === 0) && column.size > 1 ? 2 : 1;
        column.sizes.xs = column.sizes.sm;
      });

      return columns;
    },

    /**
     * Returns the number of columns that predecessors are taking for the passed
     * breakpoint.
     */
    getPredecessorsWeight(columns, index, breakpoint) {
      const predecessors = columns.filter((col, i) => i < index);

      return predecessors.reduce(
        (total, currentValue) => total + (currentValue.sizes[breakpoint] ? currentValue.sizes[breakpoint] : 1),
        0
      );
    },

    /**
     * Filter all products by activated filters (apply a AND filter)
     */
    filterProducts(productColumns, activeFilters) {
      let filteredColumns = productColumns;
      Object.entries(activeFilters).forEach(([filterId, filters]) => {
        if (filterId === 'hasprice') {
          filteredColumns = this.filterProductsByPrices(filteredColumns, filters[0], filters[1]);
          return;
        }

        const filter = this.getFilterById(filterId);
        if (filter?.grouped) {
          filters = filter.items.filter((item) => filters.includes(item.value)).flatMap((item) => item.groupValues);
        }

        filteredColumns = this.filterProductsByProperties(filteredColumns, filters, filter?.operator);
      });

      return filteredColumns;
    },

    /**
     * Filter all products by filter prices
     */
    filterProductsByPrices(productColumns, min, max) {
      let filteredColumns = filter(productColumns, (column) => {
        if (!has(column.data, 'price')) {
          return false;
        }

        const price = get(column.data.price, [window.Phoenix.currentCountry, 'value']);
        return price >= min && price <= max;
      });

      // Get all price bigger than the max product with price if the max price is Infinity
      if (max === Infinity) {
        const maxPricePosition = maxBy(filteredColumns, (column) => column.data.price_position).data.price_position;

        filteredColumns = filteredColumns.concat(
          filter(productColumns, (column) => column.data.price_position > maxPricePosition)
        );
      }

      return filteredColumns;
    },

    /**
     *  Filter product by properties (apply a OR filter or AND filter depending on the column config)
     */
    filterProductsByProperties(productColumns, propertyIds, operator) {
      return filter(productColumns, (column) =>
        operator === 'or'
          ? !isEmpty(intersection(propertyIds, column.data.product_property_ids))
          : isEmpty(difference(propertyIds, column.data.product_property_ids))
      );
    },

    /**
     * Sort the product with the current sort
     */
    sortProducts(products) {
      const sortValue = MIXED_GRID_SORT_OPTIONS[this.currentSort].field;
      const sortOrder = MIXED_GRID_SORT_OPTIONS[this.currentSort].order;

      return orderBy(
        products,
        (product) => {
          const value = product.data[sortValue];
          if (value === undefined || value.length === 0) {
            return sortOrder === 'asc' ? Infinity : -Infinity;
          }
          return Number(String(value).replace(/[^0-9]/g, ''));
        },
        [sortOrder]
      );
    },

    /**
     * Get the image from a column with the correct sources
     * @param column
     * @returns {*}
     */
    getImage(column) {
      const sizes = column.sizes;
      const stacks = column.data.stacks;
      const image = column.data.image;
      const sources = Object.keys(sizes).map((size) => stacks[sizes[size]].sources[size]);
      // Remove null values if there is not stack in a certain size
      image.sources = sources.filter((e) => e !== null && e !== undefined);

      return image;
    },

    hasDefaultSortAndFilters() {
      return this.currentSort === DEFAULT_SORT && !Object.keys(this.activeFilters).length;
    },

    setPage(page) {
      this.page = parseInt(page);

      if (this.page < 1 || isNaN(this.page)) {
        this.page = 1;
      } else if (this.page > this.biggestMaxPage) {
        this.page = this.biggestMaxPage;
      }
    },

    nextPage() {
      const nextPage = this.page + 1;
      this.trackPages(this.page, nextPage);
      this.setPage(nextPage);
    },

    seeAll() {
      this.trackPages(this.page, this.biggestMaxPage);
      this.setPage(this.biggestMaxPage);
    },

    parseUrl() {
      const queryString = queryStringParse(window.location.search);

      if (queryString.page) {
        this.setPage(queryString.page);
      } else {
        this.setPage(1);
      }
    },

    updateUrl() {
      const url = new URL(window.location.href);

      if (this.page > 1) {
        url.searchParams.set('page', this.page);
      } else if (url.searchParams.get('page') !== undefined) {
        delete url.searchParams.delete('page');
      }

      const newUrl = url.toString();
      if (window.location.href !== newUrl) {
        window.history.replaceState({ path: newUrl }, '', newUrl);
      }
    },

    getDataTracking(item) {
      return window.tracking.getProductListClickTracking(item.tracking);
    },

    getFilterById(filterId) {
      return getFilterById(this.filters, filterId);
    },
  },
};
