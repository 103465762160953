export default {
  props: {
    filter: {
      type: Object,
      required: true,
    },
    activeFilters: {
      type: Object,
      default: () => ({}),
    },
    emptyFilters: {
      type: Object,
      default: () => ({}),
    },
  },

  emits: ['onItemSelected'],

  methods: {
    itemIsActive(item) {
      return this.activeFilters[this.filter.id]?.includes(item.value) === true;
    },

    itemIsGreyedOut(item) {
      return item.disabled === true || this.emptyFilters[this.filter.id]?.includes(item.value) === true;
    },

    itemIsDisabled(item) {
      return (
        item.disabled === true ||
        (this.emptyFilters[this.filter.id]?.includes(item.value) === true &&
          // Always let the user uncheck an empty filter
          !this.itemIsActive(item))
      );
    },
  },
};
