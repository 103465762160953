<script>
import MixedGridFiltersItemList from './MixedGridFiltersItemList';
import MixedGridFiltersItemRange from './MixedGridFiltersItemRange';
import MixedGridSortSelect from './MixedGridSortSelect';
import MixedGridFiltersSelected from './MixedGridFiltersSelected';
import MixedGridFilters from '@phoenix/mixins/components/mixedGrid/MixedGridFilters';
import Panel from '../common/Panel';
import { showPanel, closePanel } from '@phoenix/helpers/panel-helper';
import { PhxIcon } from '@phx-styleguides/pia';

export default {
  components: {
    MixedGridFiltersSelected,
    MixedGridFiltersItemList,
    MixedGridFiltersItemRange,
    MixedGridSortSelect,
    Panel,
    PhxIcon,
  },

  mixins: [MixedGridFilters],

  emits: ['onSortChange', 'onFiltersChanged'],

  data() {
    return {
      panelId: 'products-filters-panel',
    };
  },

  mounted() {
    const stickyBarEl = document.querySelector('.sticky-bar');
    if (stickyBarEl) {
      window.Toolkit.stickyBar(stickyBarEl);
    }
  },

  methods: {
    showPanel,

    closePanel,
  },
};
</script>

<template>
  <div
    class="sticky-bar filters-bar filters"
    :aria-label="$t('Accessibility.ProductFiltersAndSort')"
    data-cy="mixed-grid-filters"
  >
    <div class="container container--lg">
      <div class="filters-bar__start">
        <button
          class="btn"
          type="button"
          data-cy="open-filters-panel"
          aria-expanded="false"
          @click="showPanel(panelId)"
        >
          {{ $t('Product.filters') }}
          <div class="btn__icon btn__icon--end">
            <phx-icon icon="filters" class="icon--sm" />
          </div>
        </button>
      </div>

      <div class="filters-bar__end">
        <div class="field field--switch hidden md:flex">
          <input
            id="available-online"
            v-model="availableOnline"
            name="checkbox"
            class="switch"
            type="checkbox"
            data-cy="mixed-grid-available-online"
          />

          <label id="checkbox-label" for="available-online" class="label">
            {{ $t('ProductProperty.SHOW.AVAILABLE FOR PURCHASE') }}
          </label>
        </div>

        <mixed-grid-sort-select v-model="currentSort"></mixed-grid-sort-select>
      </div>
    </div>
  </div>

  <teleport to="#teleport-target-panels">
    <panel
      id="products-filters-panel"
      :title="$t('Product.filters')"
      body-data-cy="mixed-grid-desktop-filters"
      :classes="['products-filters-panel']"
      tight
    >
      <template #content>
        <div class="box">
          <!-- ONLY ON MOBILE -->
          <div v-show="shouldDisplaySellable" class="md:hidden">
            <h6 class="products-filters-panel__title">
              {{ $t('Products.filters.quickFilters') }}
            </h6>
            <ul class="products-filters-panel__list">
              <li>
                <div class="field field--switch">
                  <input
                    id="available-online-mobile"
                    v-model="availableOnline"
                    type="checkbox"
                    class="switch"
                    name="checkbox"
                  />

                  <label for="available-online-mobile" class="label">
                    {{ $t('Products.filters.availableOnline') }}
                  </label>
                </div>
              </li>
            </ul>
          </div>
          <!-- END ONLY ON MOBILE -->

          <template v-for="filter in filters" :key="`filter-${filter.id}`">
            <mixed-grid-filters-item-list
              v-if="filter.component === 'list'"
              :filter="filter"
              :active-filters="activeFilters"
              :empty-filters="emptyFilters"
              @on-item-selected="toggleFilter"
            />
            <mixed-grid-filters-item-range
              v-if="filter.component === 'range'"
              :filter="filter"
              :symbol="getSymbolFromFilter(filter)"
              :active-filters="activeFilters"
              @on-range-change="onRangeChange"
            />
          </template>
        </div>
      </template>

      <template #footer>
        <ul class="actions actions--block" role="presentation">
          <li v-if="hasFilters" role="presentation">
            <button class="btn btn--tertiary btn--full actions--panel" type="button" @click="removeAllFilters">
              {{ $t('Search.ResetAll') }}
            </button>
          </li>
          <li role="presentation">
            <button
              type="button"
              class="btn btn--primary btn--full actions--block"
              :data-tracking="datalayerTrackingViewResults"
              data-cy="mixed-grid-filters-panel-submit-btn"
              @click="closePanel(panelId)"
            >
              <span class="btn__text">
                {{
                  $t('MixedGrid.filters.displayResults', {
                    count: productsCount,
                  })
                }}
              </span>
            </button>
          </li>
        </ul>
      </template>
    </panel>
  </teleport>

  <section class="section section--top-0 section--bottom-base theme--light section--sm">
    <div class="container container--lg">
      <mixed-grid-filters-selected
        :filters="filters"
        :active-filters="activeFilters"
        @remove-filter="removeFilter"
        @remove-all-filters="removeAllFilters"
      />
      <div
        class="products-active-filters-results"
        aria-live="polite"
        aria-atomic="true"
        data-cy="mixed-grid-products-count"
      >
        {{ productCountLabel }}
      </div>
    </div>
  </section>
</template>
