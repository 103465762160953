<script>
import MixedGridSortSelect from '@phoenix/mixins/components/mixedGrid/MixedGridSortSelect';
import Panel from '../common/Panel';
import { showPanel, closePanel } from '@phoenix/helpers/panel-helper';
import { PhxIcon } from '@phx-styleguides/pia';

export default {
  components: {
    Panel,
    PhxIcon,
  },

  mixins: [MixedGridSortSelect],

  data() {
    return {
      selectedSortBy: null,
    };
  },

  computed: {
    isApplyDisabled() {
      return !this.selectedSortBy;
    },

    panelOptions() {
      return {
        onShow: () => {
          this.selectedSortBy = this.localValue;
        },
      };
    },
  },

  methods: {
    applyChange() {
      this.localValue = this.selectedSortBy;
      this.closePanel('products-sort-by-panel');
    },

    showPanel,

    closePanel,
  },
};
</script>

<template>
  <div class="field field--horizontal">
    <button
      class="btn md:hidden"
      type="button"
      data-cy="mixed-grid-open-sort-by-panel"
      aria-expanded="false"
      @click="showPanel('products-sort-by-panel')"
    >
      {{ $t('Search.Sort.Label') }}
      <span class="btn__icon btn__icon--end">
        <phx-icon icon="sort" class="icon--sm" />
      </span>
    </button>

    <label for="mixed-grid-desktop-sort-select" class="label max-sm:sr-only">{{ sortLabel }}:</label>
    <select
      id="mixed-grid-desktop-sort-select"
      v-model="localValue"
      name="order"
      data-cy="mixed-grid-desktop-sort-by-select"
      class="input max-sm:sr-only input--select"
    >
      <option v-for="(option, val) in sortOptions" :key="option.id" :value="val">
        {{ $t(option.label) }}
      </option>
    </select>
  </div>

  <teleport to="#teleport-target-panels">
    <panel
      id="products-sort-by-panel"
      :title="$t('MixedGrid.sortBy.panel.title')"
      data-cy="mixed-grid-mobile-sort-by"
      :classes="['products-sort-by-panel']"
      :options="panelOptions"
      tight
    >
      <template #content>
        <div class="box">
          <fieldset class="fieldset fieldset--vertical" role="radiogroup">
            <h5 class="products-filters-panel__title">
              {{ $t('MixedGrid.sortBy.panel.criteria') }}
            </h5>
            <ul class="products-filters-panel__list">
              <li v-for="(option, val) in sortOptions" :key="val">
                <div class="field field--switch">
                  <input
                    :id="`mixed-grid-mobile-sort-select-${val}`"
                    v-model="selectedSortBy"
                    type="radio"
                    class="switch"
                    data-cy="mixed-grid-radio-sort-by-field"
                    name="sortByChoice"
                    :value="val"
                  />

                  <label :for="`mixed-grid-mobile-sort-select-${val}`" class="label">
                    {{ $t(option.label) }}
                  </label>
                </div>
              </li>
            </ul>
          </fieldset>
        </div>
      </template>

      <template #footer>
        <ul class="actions actions--block" role="presentation">
          <li role="presentation">
            <button
              :disabled="isApplyDisabled"
              type="button"
              class="btn btn--primary btn--full actions--block"
              data-cy="mixed-grid-sort-by-panel-submit"
              @click="applyChange"
            >
              <span class="btn__text">
                {{ $t('MixedGrid.sortBy.panel.apply') }}
              </span>
            </button>
          </li>
        </ul>
      </template>
    </panel>
  </teleport>
</template>
