<script>
import MixedGridFiltersSelected from '@phoenix/mixins/components/mixedGrid/MixedGridFiltersSelected';
import { PhxIcon } from '@phx-styleguides/pia';

export default {
  components: { PhxIcon },

  mixins: [MixedGridFiltersSelected],
};
</script>

<template>
  <div class="products-active-filters">
    <ul v-if="hasFilters" class="list list--inline list--tight list--gap-sm" data-cy="active-filters">
      <template v-for="(groupedFilters, filterId) in activeFilters" :key="filterId">
        <li v-if="isSellable(filterId)">
          <button
            class="btn btn--primary btn--xs btn--filters"
            type="button"
            :aria-label="$t('Search.RemoveFilter')"
            @click="$emit('removeFilter', filterId)"
          >
            {{ $t('ProductProperty.SHOW.AVAILABLE FOR PURCHASE') }}
            <div class="btn__icon btn__icon--end">
              <phx-icon icon="close" class="icon--2xs" />
            </div>
          </button>
        </li>

        <li v-else-if="isRange(filterId)">
          <button
            class="btn btn--primary btn--xs btn--filters"
            type="button"
            :aria-label="$t('Search.RemoveFilter')"
            @click="$emit('removeFilter', filterId)"
          >
            {{ getFilterById(filterId).label }}
            <div class="btn__icon btn__icon--end">
              <phx-icon icon="close" class="icon--2xs" />
            </div>
          </button>
        </li>

        <template v-else>
          <li v-for="value in groupedFilters" :key="value">
            <button
              class="btn btn--primary btn--xs btn--filters"
              type="button"
              :aria-label="$t('Search.RemoveFilter')"
              @click="$emit('removeFilter', filterId, value)"
            >
              {{ getFilterItemFromIdAndValue(filters, filterId, value).label }}
              <div class="btn__icon btn__icon--end">
                <phx-icon icon="close" class="icon--2xs" />
              </div>
            </button>
          </li>
        </template>
      </template>

      <li>
        <button
          class="btn btn--tertiary btn--xs"
          type="button"
          :aria-label="$t('Search.ResetAll')"
          data-cy="clear-filters-btn"
          @click="$emit('removeAllFilters')"
        >
          {{ $t('Search.ResetAll') }}
        </button>
      </li>
    </ul>
  </div>
</template>
