<script>
import MixedGridFiltersItemList from '@phoenix/mixins/components/mixedGrid/MixedGridFiltersItemList';

export default {
  mixins: [MixedGridFiltersItemList],

  computed: {
    filterClasses() {
      return ['products-filters-panel__list', this.filter.class ? this.filter.class : ''];
    },
  },
  methods: {
    getItemClasses(item) {
      return ['field field--switch', { 'field--disabled': this.itemIsGreyedOut(item) }];
    },

    getInputId(item) {
      return `filtersub-desktop-${this.filter.id}-${item.value}`;
    },
  },
};
</script>

<template>
  <div data-cy="mixed-grid-filter-list">
    <h6 class="products-filters-panel__title">{{ filter.label }}</h6>
    <ul :class="filterClasses" :data-cy="filter.id">
      <li v-for="item in filter.items" :key="item.value">
        <div :class="getItemClasses(item)">
          <input
            :id="getInputId(item)"
            :data-cy="getInputId(item)"
            :data-property="filter.id"
            :data-value="item.value"
            :disabled="itemIsDisabled(item)"
            :checked="itemIsActive(item)"
            type="checkbox"
            class="switch"
            :name="getInputId(item)"
            :value="item.value"
            @change="$emit('onItemSelected', filter.id, item.value)"
          />

          <label :for="getInputId(item)" class="label">
            {{ item.label }}
          </label>
        </div>
      </li>
    </ul>
  </div>
</template>
