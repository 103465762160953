import { uniqueId, has, map } from 'lodash-es';
import { Range } from '@maison/Toolkit';

export default {
  props: {
    filter: {
      type: Object,
      required: true,
    },
    activeFilters: {
      type: Object,
      default: () => ({}),
    },
    symbol: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      uniqueId: uniqueId('range_'),
      widget: undefined,
      values: undefined,
      options: {},
    };
  },

  watch: {
    // Recreate the widget if the filter has been removed
    activeFilters(newActiveFilters, oldActiveFilters) {
      if (
        this.options &&
        this.widget &&
        !has(newActiveFilters, this.filter.id) &&
        has(oldActiveFilters, this.filter.id)
      ) {
        this.init();
      }
    },
    // Watch the symbol to rebuild the widget
    symbol() {
      this.init();
    },
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.destroyRange();

      this.options = this.filter.options;
      if (!this.options.format) {
        this.options.noUiSliderOptions.tooltips = [
          this.rangeFormatter(this.filter.items),
          this.rangeFormatter(this.filter.items),
        ];
      }

      if (has(this.activeFilters, this.filter.id)) {
        const activeValues = this.activeFilters[this.filter.id];
        if (activeValues.length === 2) {
          this.options.noUiSliderOptions.start = [...activeValues];
        } else {
          const startItem = this.filter.items.find((item) => item.value === activeValues[0]);
          if (startItem !== undefined) {
            this.options.noUiSliderOptions.start[0] = this.filter.items.indexOf(startItem);
          }

          const endItem = this.filter.items.find((item) => item.value === activeValues[activeValues.length - 1]);
          if (endItem !== undefined) {
            this.options.noUiSliderOptions.start[1] = this.filter.items.indexOf(endItem);
          }
        }
      }

      this.initRange();
    },

    initRange() {
      this.widget = new Range(this.$refs.range, this.options);
      this.widget.getNoUiSlider().on('set', this.onRangeSet);
    },

    destroyRange() {
      if (this.widget) {
        this.widget.destroy();
        this.widget = undefined;
      }
    },

    onRangeSet() {
      const [start, end] = this.widget.getValue();
      const continuous = this.options.noUiSliderOptions.snap === false;

      let values = [];
      if (continuous) {
        if (start === this.options.noUiSliderOptions.range.min && end === this.options.noUiSliderOptions.range.max) {
          values = [];
        } else {
          values = [start, end];
        }
      } else {
        if (start > 0 || end < this.filter.items.length - 1) {
          values = this.filter.items.slice(start, end + 1);
        }
      }

      this.values = continuous ? values : map(values, (value) => value.value);

      this.$emit('onRangeChange', this.filter, this.values);
    },

    rangeFormatter(items) {
      return {
        to: (value) => items[value].label,
        from: (value) => value,
      };
    },
  },
};
